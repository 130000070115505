import React, { useRef } from "react";
import { device } from '../MainContents/Style/StyleDevice';
import UniversityTable from "./UniversityTable";
import UniversityTable2025 from "./UniversityTable2025";
import SuccessList from "./SuccessList";
import ImgSuccessList from '../../img/successList.jpg';
import styled from "styled-components";
import FlashReport from "./FlashReport";
const DivTop = styled.div`
    margin: auto;
    margin-top: 170px;
    width: 90%;
  @media ${device.mobile} {
    margin-top: 80px;
    width: 95%;
  }
`;
const Iframe = styled.iframe`
    width: 100%;
    height :400px;
    overflow: auto; /* スクロールバーを非表示 */
`;

const schoolDataKokouritu = [
    { name: "京都大", count: 1 },
    { name: "神戸大", count: 2 },
    { name: "島根大", count: 2 },
    { name: "広島大(医)", count: 1 },
    { name: "香川大", count: 1 },
    { name: "愛媛大", count: 1 },
    { name: "高知大", count: 6 },
    { name: "東京都立大", count: 1 },
    { name: "静岡文化芸術大", count: 1 },
    { name: "大阪公立大", count: 1 },
    { name: "広島市立大", count: 1 },
    { name: "高知県立大", count: 1 },
];

const schoolDataKokourituKouki = [
    { name: "電気通信大", count: 1 },
    { name: "大阪公立大", count: 1 },
    { name: "高知大", count: 1 },
    { name: "高知工科大", count: 1 },
];

function Success() {

    return (
        <DivTop>
            {/* 見出し */}
            <h1 className="mb-6 text-3xl font-bold text-black border-b-4 border-red-500 pb-2 inline-block shadow-sm">
            2025年度入試　土佐塾予備校　合格実績
            </h1>
            <UniversityTable2025 />
            {/* <FlashReport 
                titleSokuhou="国公立大中期・後期合格" 
                postingSokuhou="2025年国公立大中期・後期日程合格(3/23判明分)"
                schoolData={schoolDataKokourituKouki} 
            />

            <FlashReport 
                titleSokuhou="2025年国公立大学合格速報" 
                postingSokuhou="2025年国公立大合格速報(3/10判明分)"
                schoolData={schoolDataKokouritu} 
            /> */}

            <div className="mt-10 mb-0">
                <div className="text-center bg-red-100 rounded-md p-3 shadow-md mb-6 relative">
                    {/* 桜の装飾（オプション） */}
                    <div className="absolute left-2 top-2 opacity-30">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="pink">
                            <path d="M12 2c1.1 0 2 .9 2 2 0 .45-.15.86-.4 1.2.58-.12 1.22-.2 1.88-.2 2.21 0 4 1.79 4 4 0 .99-.37 1.9-.98 2.6.2-.03.4-.06.6-.06 1.1 0 2 .9 2 2 0 1.24-.95 2.25-2.17 2.38 0 .07.01.14.01.22 0 2.21-1.79 4-4 4-1.06 0-2.03-.42-2.74-1.1-.71.68-1.68 1.1-2.74 1.1-2.21 0-4-1.79-4-4 0-.08.01-.15.01-.22C4.95 16.25 4 15.24 4 14c0-1.1.9-2 2-2 .2 0 .4.03.6.06-.61-.7-.98-1.61-.98-2.6 0-2.21 1.79-4 4-4 .66 0 1.3.08 1.88.2A1.99 1.99 0 0112 2z" />
                        </svg>
                    </div>

                    {/* 見出し */}
                    <h1 className="text-3xl font-bold text-black border-b-4 border-red-500 pb-2 inline-block shadow-sm">
                        2011～2024 合格実績
                    </h1>
                </div>
                <UniversityTable />
            </div>
            {/* <div class="table__container js-scrollable">
            <Iframe src={'https://tosayobi.ac.jp/Success/'}></Iframe>
        </div> */}
            <hr />
            <h3 className="mt-12 text-2xl text-pink-400 font-bold w-3/4 mx-auto"><span className="pr-3 align-middle"><span class="material-symbols-outlined">
                comment
            </span></span>2025年春　合格体験記</h3>

            <hr className="w-3/4 mx-auto" />

            {/* 2025年 STR */}
            <SuccessList
                college={'京都大学'} college_faculty={'工学部'} school={'土佐高校'} name={'Ｍ・Ｈ'}
                comment={'志望校に合格できるかどうかは、どれだけ基礎をしっかり固めることができたかで決まると思います。試験中に難しい問題を解き切ることのできる受験生は少ないです。実際、私自身も入試本番では難しかった問題はほとんど解けませんでした。だからこそ、標準的な難易度の問題をどれだけミスなく解けたかで他の受験生との差がつきます。そのため、これから浪人をする人たちには、基礎をおろそかにせずに勉強していってほしいです。また、TOSAYOBIの先生方は基礎力を身につける上で大きな力になってくれると思います。第一志望合格を目指して頑張ってください。'}
            />
            <SuccessList
                college={'東京都立大学'} college_faculty={'人文社会学部'} school={'土佐高校'} name={'Ｆ・Ｒ'}
                comment={'現役の頃は学部選びも曖昧で、なんとなく合格しそうな大学を目指していました。しかし、不合格を経験し、TOSAYOBIでの浪人生活で自分と向き合う時間が増えたことで、本当にやりたいことを深く考えるようになりました。その結果、自分に合う学部を見つけ、明確な目標を持って勉強に取り組めるようになりました。浪人生は現役生と比べて時間的な余裕がある分、自分を見つめ直す良い機会にもなると思います。'}
            />
            <SuccessList
                college={'広島大学'} college_faculty={'医学部（医）'} school={'土佐高校'} name={'Ｙ・Ｓ'}
                comment={'みんなそれぞれ得意・不得意な科目があります。難関大に合格するためにはまず、得意科目の得点をさらに伸ばすことが私にとっては重要だと考え、高い数値目標を理科と数学で設定し、勉強時間の大半をそれらにつぎ込みました。次に、苦手なことについても向き合わないといけないため、まずは英単語など基本的なことからやり直しました。苦手科目の中に得意分野をつくっていくことも大切です。浪人してでもあと１年頑張ろうとしている皆さん。つらい１年になるでしょうが、小さな成長を喜び、それを糧に頑張ってください。'}
            />
            <SuccessList
                college={'島根大学'} college_faculty={'人間科学部'} school={'高知学芸高校'} name={'Ｋ・Ｈ'}
                comment={'勉強をするときに特に重要なのは、環境と学習方法ではないかと思います。私自身、周りの音やものに気が散ってしまい集中できなかったり、学習方法が分からず闇雲に勉強したりしていました。そういう時、TOSAYOBIの先生方に相談してみるときちんと話を聞いてくれました。自分にあった学習方法も見つかり、学習環境が整った状態での勉強は意外と楽しいものでした。自習室で勉強していて疲れたと感じれば、TOSAYOBIの周囲を少し散歩してリフレッシュしながら、自分のペースで自分なりの勉強をしてみるのも良いかと思います。'}
            />
            <SuccessList
                college={'香川大学'} college_faculty={'教育学部'} school={'高知学芸高校'} name={'Ｋ・Ｓ'}
                comment={'浪人生活は最初の頃はとてもつらかったですが、毎朝TOSAYOBIに行くと周りにも同じ浪人生がいたので心強く感じられました。家族、友人、TOSAYOBIの先生方などたくさんの方々のサポートおかげで入試に挑むことができました。その結果、第一志望の大学に合格することができ、この１年間の努力が報われたと今、実感しています。現役の時には受験に失敗したけれど、忍耐力や継続力が身についたので、TOSAYOBIでリベンジして良かったです。'}
            />
            <SuccessList
                college={'愛媛大学'} college_faculty={'法文学部'} school={'土佐塾高校'} name={'Ｋ・Ｙ'}
                comment={'私は高校時代、あまり勉強に向き合うことができず、全落ちするくやしい結果となりました。TOSAYOBIに入ってからは、苦手だった国語を中心に基礎から勉強し直すことにしました。一年間自分のペースを保って勉強できたため、共通テスト本番では自己ベストを更新することができました。浪人生活はとてもつらいと思いますが、最後まで諦めずに頑張ってください。'}
            />

            <SuccessList
                college={'高知大学'} college_faculty={'教育学部'} school={'高知学芸高校'} name={'Ｋ・Ｋ'}
                comment={'私の浪人の１年は波のある１年でした。何回も心が折れ、勉強が続かなくなっていましたが、クラス主任から毎週送られてくるクラスＬＩＮＥや、人当たりがよく受験生が後悔しない選択ができるように背中を押してくれる校長のおかげで立ち直ることができました。共通テストは第１志望がＢ判定、高知大学はＡ判定がとれました。出願校選びにかなり悩みましたが、そこでもまたクラス主任と校長に親身になって相談にのってもらい本当に感謝しかありません。TOSAYOBIでよかった。'}
            />
            <SuccessList
                college={'高知大学'} college_faculty={'教育学部'} school={'高知丸の内高校'} name={'Ｋ・Ｍ'}
                comment={'私は、頭がよいタイプではなかったと思います。また、夏からTOSAYOBIに入学して勉強を始めたため、かなり周囲と差ができていたと思います。しかし、自習室が閉まるまでできる限り勉強を続けることで共通テスト本番では、公民が８割近くの得点をとることができました。私は、本人のやる気と努力次第で成績は確実に上がると確信しています。TOSAYOBIの先生方もとても親身になってくれるので受験をする環境として十分だと思います。'}
            />
            <SuccessList
                college={'高知大学'} college_faculty={'医学部（看護）'} school={'鹿島朝日高校'} name={'Ｋ・Ｍ'}
                comment={'私はTOSAYOBIに一年間通うことで、苦手教科の勉強はもとより、自分の将来をじっくり考えることができました。苦手教科の勉強は本当に億劫だったのですが、先生方による添削指導のおかげでしっかり向き合うことができ、共通テストの得点を現役の頃より上げることができました。また、昨年までの私は出願校についてとても視野が狭かったのですが、クラス主任の先生が一緒に考えてくださり、将来の方向性やそのために進学する大学を決めることができました。浪人の一年間は勉強だけでなく自分の将来についても考えるよい機会になると思います。'}
            />
            <SuccessList
                college={'静岡文化芸術大'} college_faculty={'デザイン学部'} school={'土佐高校'} name={'Ｔ・Ｒ'}
                comment={'私の受験した大学の入試には数学か実技（鉛筆デッサン）の２種類の受験方式があり、私は現役の時に前期試験・後期試験ともに実技で受験し、全敗してしまいました。数学で受験することが眼中になく、視野が狭まっていたことを反省し、浪人して前半は画塾での実技対策とTOSAYOBIでの二次数学対策を並行しておこなっていました。後半になると数学の方が自分には向いていると気づき、数学で受験することに決めました。TOSAYOBIでの１年を通し、自分の苦手得意を見極め、自分で決断することの大切さを学びました。先生方には感謝してもしきれません。この１年を忘れることはできません。'}
            />
            <SuccessList
                college={'高知県立大学'} college_faculty={'社会福祉学部'} school={'高知学芸高校'} name={'Ｏ・Ｒ'}
                comment={'私は、TOSAYOBIでの浪人の一年間で、地道に努力を重ねることの重要性を学びました。現役時代は努力を重ねることが苦手だったのですが、TOSAYOBIの先生方の授業や指導によって、無事、今年の共通テストで点をとることができました。２次試験で必要だった面接も、クラス主任の先生にしっかり対策していただきました。最後に、受験生の方へ。周りの人たちと比べるのではなく、過去の自分と比べ、日々進歩していることを実感し、それを自信につなげて受験勉強を頑張ってください。'}
            />
            {/* 2025年 END */}
        </DivTop>
    );
}

export default Success;
